.select1 {
  animation-name: opa1;
  animation-duration: .5s;
}
.select2 {
  animation-name: opa2;
  animation-duration: .5s;
}
.select3 {
  animation-name: opa3;
  animation-duration: .5s;
}

@keyframes opa1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opa2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opa3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
