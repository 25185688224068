.notif{
    position:relative !important;
}

.notif::after{
   content:"";
   display:block;
   width: 13px;
   height: 13px;
   border-radius: 50%;
   background-color: red;
   position:absolute;
    top:0;
    right:0;
    border:2px solid white;
}